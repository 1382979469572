<template>
  <div>
    <div v-if="error" class="sm:container">
      {{ error }}
    </div>
    <div v-else>
      <div
        v-if="featuredAssetPackages.length > 0"
        class="mb-8 md:mb-16 md:container"
      >
        <div class="mb-6 hidden md:block">
          <div class="inline-flex items-center">
            <IconStar class="mr-2 h-6 w-6 flex-shrink-0 text-neutral-300" />
            <div class="text-sm font-medium text-neutral-900">
              {{ $t("pages.brandPackagesOverview.essentials") }}
            </div>
          </div>
        </div>
        <div
          class="featured-card-slider flex-no-wrap flex md:grid md:grid-cols-4 md:gap-5 xl:gap-8"
        >
          <div
            v-for="assetPackage in featuredAssetPackages"
            :key="assetPackage.uuid"
            class="featured-card-item flex flex-shrink-0 flex-col"
          >
            <FeaturedCard
              :asset-package="assetPackage"
              :router-link-name="'brand-marketing-packages-detail'"
            />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="mb-6 hidden md:block">
          <div class="inline-flex items-center">
            <IconPackage class="mr-2 h-6 w-6 flex-shrink-0 text-neutral-300" />
            <div class="text-sm font-medium text-neutral-900">
              {{ $t("pages.brandPackagesOverview.title") }}
            </div>
          </div>
        </div>
      </div>
      <div class="sm:container">
        <div class="grid grid-cols-1 gap-4 sm:mx-auto md:grid-cols-12 md:gap-8">
          <div class="px-5 md:col-start-1 md:col-end-5 md:px-0 xl:col-end-4">
            <FilterFrame
              :results-text="
                this.$i18n.tc(
                  `pages.brandPackagesOverview.filterResult`,
                  assetPackages.length,
                  {
                    amount: assetPackages.length,
                  },
                )
              "
            >
              <PackageFilter
                :filters="categories"
                :selected-filters="categoriesFilter"
                :loading="categoriesLoading"
                @selectedFilter="updateCategoriesFilter"
              />
            </FilterFrame>
          </div>
          <div class="md:col-start-5 md:col-end-13 xl:col-start-4">
            <div v-if="assetPackagesLoading">
              <LoadingIndicator class="mx-auto mt-8 w-8" />
            </div>
            <div
              v-else-if="assetPackages.length > 0"
              class="grid grid-cols-1 gap-1 md:gap-4 xl:grid-cols-2"
            >
              <PackageRow
                v-for="assetPackage in assetPackages"
                :key="assetPackage.uuid"
                :asset-package="assetPackage"
                :router-link-name="'brand-marketing-packages-detail'"
              />
            </div>
            <EmptyState v-else @resetFilters="resetCategoriesFilter" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PackageFilter from "@/components/brand-marketing/packages/PackageFilter";
import PackageRow from "@/components/brand-marketing/packages/PackageRow";
import FeaturedCard from "@/components/brand-marketing/packages/FeaturedCard";
import FilterFrame from "@/components/brand-marketing/FilterFrame";
import EmptyState from "@/components/brand-marketing/EmptyState";

import FIND_FEATURED_ASSET_PACKAGES from "@/graphql/FindFeaturedAssetPackages.gql";
import FIND_ASSET_PACKAGES from "@/graphql/FindAssetPackages.gql";
import FIND_ASSET_CATEGORIES_WITH_PACKAGES from "@/graphql/FindAssetCategoriesWithAssetPackages.gql";

export default {
  components: {
    FilterFrame,
    PackageFilter,
    PackageRow,
    EmptyState,
    FeaturedCard,
  },

  data() {
    return {
      categories: [],
      assetPackages: [],
      error: null,
      categoriesFilter: [],
      featuredAssetPackages: [],
    };
  },

  computed: {
    categoriesLoading() {
      return this.$apollo.queries.categories.loading;
    },
    assetPackagesLoading() {
      return this.$apollo.queries.assetPackages.loading;
    },
  },

  beforeRouteEnter(_to, _from, next) {
    document.body.classList.remove("overflow-y-hidden");
    next();
  },

  created() {
    this.$parent.$on("emitResetFilters", this.resetCategoriesFilter);
  },

  apollo: {
    featuredAssetPackages: {
      query: FIND_FEATURED_ASSET_PACKAGES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },

      fetchPolicy: "network-only",
    },
    assetPackages: {
      query: FIND_ASSET_PACKAGES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
      variables() {
        return {
          categories: this.categoriesFilter,
        };
      },
      fetchPolicy: "network-only",
    },
    categories: {
      query: FIND_ASSET_CATEGORIES_WITH_PACKAGES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
  },

  methods: {
    updateFilter(filter, uuid) {
      //@TODO Unit test this method
      if (filter.includes(uuid)) {
        filter.splice(filter.indexOf(uuid), 1);
      } else {
        filter.push(uuid);
      }
    },
    updateCategoriesFilter(filter) {
      this.updateFilter(this.categoriesFilter, filter.uuid);
    },
    resetCategoriesFilter() {
      this.categoriesFilter = [];
    },
  },
};
</script>

<style scoped>
.featured-card-slider {
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  @apply overflow-x-auto;
}

.featured-card-item {
  scroll-snap-align: start;
  @apply pl-5 pb-5;
}
.featured-card-item:last-child {
  @apply pr-5;
}
.featured-card-item > * {
  @apply w-60vw;
}

@screen md {
  .featured-card-item {
    @apply px-0 pb-0;
  }
  .featured-card-item:last-child {
    @apply pr-0;
  }
  .featured-card-item > * {
    @apply w-auto;
  }
}
</style>
