var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"border focus:outline-none inline-flex items-center rounded-sm border-neutral-50 bg-white py-2 px-4 pr-5 text-sm font-medium md:hidden",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleMobileFilter()}}},[_c('IconFilter',{staticClass:"mr-2 h-5 w-5 text-neutral-400"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("components.brandMarketing.filterFrame.filterButton"))+" ")])],1),_c('div',{class:[
      'scrolling-touch transition fixed left-0 bottom-0 z-40 h-screen max-h-screen w-full transform overflow-y-scroll bg-white pt-8 duration-300 md:relative md:z-0 md:block md:h-auto md:max-h-none md:overflow-hidden md:bg-transparent md:pt-0',
      {
        'translate-y-full opacity-0 md:translate-y-0 md:opacity-100':
          !_vm.mobileFilterOpen,
      } ]},[_vm._t("default")],2),_c('button',{class:[
      'transition fixed bottom-0 left-0 right-0 z-50 w-full bg-primary-600 px-5 py-3 text-sm font-bold text-white duration-300 md:hidden',
      { 'invisible translate-y-full': !_vm.mobileFilterOpen } ],attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleMobileFilter()}}},[_vm._v(" "+_vm._s(_vm.resultsText)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }