<template>
  <div class="sm:border rounded-sm border-neutral-50 bg-white p-5">
    <div class="mb-5 text-sm font-medium text-neutral-900">
      {{ $t("components.brandMarketing.packages.packageFilter.title") }}
    </div>
    <div v-if="loading">
      <LoadingIndicator class="mx-auto mt-8 w-8" />
    </div>
    <div v-if="!loading">
      <div
        v-for="(filter, index) in filters"
        :key="index"
        class="relative mb-4 last:mb-0"
      >
        <label
          class="group relative flex cursor-pointer items-center text-sm text-neutral-600"
        >
          <input
            :checked="isSelected(filter)"
            type="checkbox"
            class="absolute top-0 left-0 opacity-0"
            @change="select(filter)"
          />
          <div
            class="mr-2 flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-sm border-2 border-neutral-100 bg-white transition-all duration-200 group-hover:border-neutral-300"
          >
            <IconCheckbox class="opacity-0" />
          </div>
          <div class="flex-grow flex justify-between">
            <div>{{ filter.name }}</div>
            <div
              class="flex items-center justify-center rounded-full bg-neutral-50 px-2 text-xs font-medium text-neutral-600"
            >
              <span>{{ filter.assetPackages.length }}</span>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectedFilters: {
      type: Array,
      required: true,
    },
  },

  methods: {
    select(filter) {
      this.$emit("selectedFilter", filter);
    },
    isSelected(filter) {
      return this.selectedFilters.includes(filter.uuid);
    },
  },
};
</script>

<style scoped>
input[type="checkbox"]:checked + div {
  @apply border-primary-50 bg-primary-50;

  & + div {
    @apply text-neutral-900;
  }

  & > svg {
    @apply text-primary-600 opacity-100;
  }
}
</style>
