<template>
  <div>
    <div v-click-outside="closeTools" class="relative flex">
      <div
        class="transition-color focus:outline-none cursor-pointer text-neutral-300 duration-300 hover:text-neutral-900 focus:text-primary-600"
        @click.stop="toggleTools()"
      >
        <IconDots />
      </div>
      <ul
        v-if="toolsOpen"
        class="border rounded absolute top-full right-0 z-20 -mt-1 border-neutral-50 bg-white py-2 shadow-md"
      >
        <li v-for="(item, index) in showableItems" :key="index" class="flex">
          <button
            :class="[
              'whitespace-no-wrap transition focus:outline-none w-full py-1 pl-3 pr-6 text-left text-sm duration-300 hover:bg-neutral-0 focus:bg-neutral-0',
              { 'text-danger-600 hover:text-danger-900': item.destructive },
              { 'text-neutral-600 hover:text-neutral-900': !item.destructive },
            ]"
            @click="handleClick(item.callback)"
          >
            {{ item.name }}
          </button>
        </li>
        <slot></slot>
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  directives: {
    ClickOutside,
  },
  props: {
    items: {
      type: Array,
      required: true,
      validator: function (values) {
        return values.every((value) => {
          if ("name" in value && "showIf" in value && "callback" in value) {
            return true;
          }
          return false;
        });
      },
    },
  },

  data() {
    return {
      toolsOpen: false,
    };
  },

  computed: {
    showableItems() {
      return this.items.filter((item) => item.showIf);
    },
  },

  methods: {
    toggleTools() {
      this.toolsOpen = !this.toolsOpen;
    },
    closeTools() {
      if (this.toolsOpen) {
        this.toolsOpen = false;
      }
    },
    handleClick(callback) {
      this.closeTools();
      callback();
    },
  },
};
</script>
