<template>
  <router-link
    :to="{
      name: routerLinkName,
      params: { packageUuid: assetPackage.uuid },
    }"
    class="flex-grow group flex flex-col overflow-hidden rounded-sm bg-white"
  >
    <div class="relative bg-neutral-50 pt-3/5">
      <div
        v-if="assetPackage.coverImage"
        class="absolute top-0 left-0 h-full w-full bg-cover bg-center"
        :style="{ backgroundImage: `url(${assetPackage.coverImage.url})` }"
      ></div>
      <div
        v-else
        class="absolute top-0 left-0 flex h-full w-full items-center justify-center"
      >
        <IconBorettis class="h-20 w-20 text-neutral-300" />
      </div>
      <div
        class="absolute top-4 left-0 flex h-6 items-center rounded-r-sm bg-black bg-alpha-light-60 px-1 pr-2 text-2xs font-medium text-white"
      >
        <IconClock class="mr-1 h-4 w-4" />
        <span>{{ dueDate }}</span>
      </div>
    </div>
    <div
      class="border flex-grow rounded-b-sm border-t-0 border-neutral-50 p-5 group-hover:border-neutral-100"
    >
      <div class="mb-3 flex justify-between">
        <div class="pr-3">
          <h3 class="text-sm font-medium text-neutral-900">
            {{ assetPackage.name }}
          </h3>
          <div class="text-xs text-neutral-400">
            {{
              $tc(
                "components.brandMarketing.packages.packageRow.assetsCount",
                assetPackage.assetCount,
                {
                  amount: assetPackage.assetCount,
                },
              )
            }}
            <span v-if="assetPackage.file">, {{ humanReadableSize }}</span>
          </div>
        </div>
        <BaseButton
          v-if="assetPackage.file"
          size="medium"
          variant="outline"
          class="flex-shrink-0"
          @buttonClick="downloadAssetPackage(assetPackage)"
        >
          <template #iconCenter>
            <IconDownload />
          </template>
        </BaseButton>
      </div>
      <p v-if="assetPackage.description" class="text-xs text-neutral-600">
        {{ assetPackage.description }}
      </p>
    </div>
  </router-link>
</template>

<script>
import { mapActions } from "vuex";
import humanReadableBytes from "@/utils/humanReadableBytes";
export default {
  props: {
    assetPackage: {
      type: Object,
      required: true,
    },
    routerLinkName: {
      type: String,
      required: true,
    },
  },
  computed: {
    humanReadableSize() {
      return humanReadableBytes(this.assetPackage.file.size);
    },
    dueDate() {
      const date = new Date(this.assetPackage.dueDate);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
  },
  methods: {
    ...mapActions("assetPackage", ["downloadAssetPackage"]),
  },
};
</script>
