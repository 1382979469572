<template>
  <div
    class="rounded sm:border flex cursor-pointer items-center border-neutral-50 bg-white transition-all duration-200 hover:border-neutral-200"
  >
    <router-link
      :to="{
        name: routerLinkName,
        params: { packageUuid: assetPackage.uuid },
      }"
      class="flex-grow flex p-5 py-4 pr-0 sm:p-6"
    >
      <div class="mr-3 flex-shrink-0">
        <IconPackageEmpty
          v-if="assetPackage.assetCount === 0"
          class="text-neutral-300"
        />
        <IconPackage v-else class="h-6 w-6 text-primary-600" />
      </div>
      <div>
        <div class="pr-4">
          <span class="text-sm font-medium text-neutral-900">
            {{ assetPackage.name }}
          </span>
          <span
            v-if="assetPackage.description"
            class="group relative hidden lg:inline"
          >
            <IconInfo
              class="inline h-5 w-5 text-neutral-300 transition-all duration-300 group-hover:text-neutral-900"
            />
            <span
              class="rounded invisible absolute left-1/2 bottom-full w-56 -translate-x-1/2 transform bg-neutral-900 p-3 text-2xs text-white opacity-0 transition-all duration-300 group-hover:visible group-hover:opacity-100"
            >
              <span
                class="absolute bottom-0 left-0 right-0 mx-auto -mb-1 h-2 w-2 rotate-45 transform bg-neutral-900"
              ></span>
              <span>
                {{ assetPackage.description }}
              </span>
              <div class="mt-2 font-bold">
                <span>
                  {{
                    $t(
                      "components.brandMarketing.packages.packageRow.downloadBefore",
                    )
                  }}
                </span>
                <span>{{ dueDate }}</span>
              </div>
            </span>
          </span>
        </div>
        <div class="text-xs text-neutral-400">
          {{
            $tc(
              "components.brandMarketing.packages.packageRow.assetsCount",
              assetPackage.assetCount,
              {
                amount: assetPackage.assetCount,
              },
            )
          }}
          <span v-if="assetPackage.file">, {{ humanReadableSize }}</span>
        </div>
      </div>
    </router-link>
    <div class="flex">
      <BaseButton
        v-if="assetPackage.file"
        size="medium"
        variant="outline"
        class="mr-3"
        @buttonClick="downloadAssetPackage(assetPackage)"
      >
        <template #iconCenter>
          <IconDownload />
        </template>
      </BaseButton>
      <OverflowMenu class="flex items-center pr-5 sm:pr-6" :items="menuItems" />
    </div>
  </div>
</template>

<script>
import humanReadableBytes from "@/utils/humanReadableBytes";
import OverflowMenu from "@/components/common/OverflowMenu";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    OverflowMenu,
  },

  props: {
    assetPackage: {
      type: Object,
      required: true,
    },
    routerLinkName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      menuItems: [
        {
          name: this.$i18n.t(
            "components.brandMarketing.packages.packageRow.overflowMenu.editDetails",
          ),
          showIf: this.$can("write:assetPackages"),
          callback: () =>
            this.$router.push({
              name: "brand-marketing-packages-detail",
              params: {
                packageUuid: this.assetPackage.uuid,
                openEditModal: true,
              },
            }),
        },
        {
          name: this.$i18n.t(
            "components.brandMarketing.packages.packageRow.overflowMenu.viewPackage",
          ),
          showIf: this.$can("read:assetPackages"),
          callback: () =>
            this.$router.push({
              name: "brand-marketing-packages-detail",
              params: { packageUuid: this.assetPackage.uuid },
            }),
        },
        {
          name: this.$i18n.t(
            "components.brandMarketing.packages.packageRow.overflowMenu.disbandPackage",
          ),
          showIf: this.$can("write:assetPackages"),
          callback: () =>
            this.$router.push({
              name: "brand-marketing-packages-detail",
              params: {
                packageUuid: this.assetPackage.uuid,
                openEditModal: true,
                highlightEditModalDisbandButton: true,
              },
            }),
        },
      ],
    };
  },

  computed: {
    assetText() {
      if (this.assetPackage.assetCount === 1) {
        return "asset";
      }
      return "assets";
    },
    humanReadableSize() {
      return humanReadableBytes(this.assetPackage.file.size);
    },
    dueDate() {
      const date = new Date(this.assetPackage.dueDate);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
    ...mapGetters("user", ["currentUser"]),
  },

  methods: {
    ...mapActions("assetPackage", ["downloadAssetPackage"]),
  },
};
</script>
