<template>
  <div
    class="border rounded-sm border-neutral-50 bg-white p-8 px-5 text-center animation-fadeIn animation-50 animation-once md:p-12"
  >
    <div class="mb-1 text-lg font-bold">
      {{ $t("components.brandMarketing.emptyState.title") }}
    </div>
    <div class="mb-6 text-sm text-neutral-600">
      {{ $t("components.brandMarketing.emptyState.text") }}
    </div>
    <BaseButton
      size="medium"
      variant="outline"
      :text="$t(`components.brandMarketing.emptyState.button`)"
      @buttonClick="resetFilters"
    ></BaseButton>
  </div>
</template>

<script>
export default {
  methods: {
    resetFilters() {
      this.$emit("resetFilters");
    },
  },
};
</script>
