<template>
  <div>
    <button
      type="button"
      class="border focus:outline-none inline-flex items-center rounded-sm border-neutral-50 bg-white py-2 px-4 pr-5 text-sm font-medium md:hidden"
      @click="toggleMobileFilter()"
    >
      <IconFilter class="mr-2 h-5 w-5 text-neutral-400" />
      <span>
        {{ $t("components.brandMarketing.filterFrame.filterButton") }}
      </span>
    </button>
    <div
      :class="[
        'scrolling-touch transition fixed left-0 bottom-0 z-40 h-screen max-h-screen w-full transform overflow-y-scroll bg-white pt-8 duration-300 md:relative md:z-0 md:block md:h-auto md:max-h-none md:overflow-hidden md:bg-transparent md:pt-0',
        {
          'translate-y-full opacity-0 md:translate-y-0 md:opacity-100':
            !mobileFilterOpen,
        },
      ]"
    >
      <slot></slot>
    </div>
    <button
      type="button"
      :class="[
        'transition fixed bottom-0 left-0 right-0 z-50 w-full bg-primary-600 px-5 py-3 text-sm font-bold text-white duration-300 md:hidden',
        { 'invisible translate-y-full': !mobileFilterOpen },
      ]"
      @click="toggleMobileFilter()"
    >
      {{ resultsText }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    resultsText: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      mobileFilterOpen: false,
    };
  },

  methods: {
    toggleMobileFilter() {
      this.mobileFilterOpen = !this.mobileFilterOpen;
    },
  },
};
</script>
